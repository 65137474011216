import type { ComponentType } from "react"
import React, { useEffect, useRef, useState } from "react"

import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { getPropertiesByTag } from "https://framer.com/m/Utilities-LgUQ.js@YgTH7d8XoflBisXAiR7m"

const useStore = createStore({
    titles: [],
    chagedLink: false,
    className: "",
    pTagStyle: {},
    pTagClass: "",
    aTagClass: "",
})

function getSecondPart(str) {
    return str.split("/")[1]
}

export function getLinks(Component): ComponentType {
    return (props) => {
        const [boolean, setBoolean] = useState(false)
        const [store, setStore] = useStore()
        const Link = useRef(null)
        useEffect(() => {
            setBoolean(window.location.href.includes(props.link))
            setStore({ chagedLink: true })
        }, [])

        props.className += " sidebar-link"
        return (
            <Component
                {...props}
                variant={boolean ? "Select" : "Default"}
                ref={Link}
            />
        )
    }
}

export function getTitles(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        useEffect(() => {
            var html = document.querySelector("html")
            html.style.scrollBehavior = "smooth"

            var content = document.getElementById("content-data")

            if (!content) {
                return
            }

            const ptagProps = getPropertiesByTag(props, "p")
            const atagProps = getPropertiesByTag(ptagProps || {}, "a")

            var headings = content.querySelectorAll("h2")
            var headingsArray = []

            for (let i = 0; i < headings.length; i++) {
                const element = headings[i]
                element.style.position = "relative"
                element.insertAdjacentHTML(
                    "beforeend",
                    `<span id="title-${i}" style="position: absolute; top:-144px;"></span>`
                )
                headingsArray.push(
                    element.innerText.replace(/(\r\n|\n|\r)/gm, "")
                )
            }

            setStore({
                titles: headingsArray,
                pTagStyle: ptagProps?.props?.style || {},
                pTagClass: ptagProps?.props?.className || "",
                aTagClass: atagProps?.props?.className || "",
            })
        }, [])

        const divStyles = {
            display: "flex",
            flexDirection: "column",
            gap: "12px",
        }

        return (
            <div style={divStyles}>
                {Array.isArray(store.titles)
                    ? store.titles.map((element, i) => {
                          return (
                              element && (
                                  <p
                                      className={`framer-text ${store.pTagClass}`}
                                      style={store.pTagStyle}
                                  >
                                      <a
                                          href={"#title-" + i}
                                          className={`framer-text ${store.aTagClass}`}
                                          key={i}
                                      >
                                          {element}
                                      </a>
                                  </p>
                              )
                          )
                      })
                    : null}
            </div>
        )
    }
}

export function setActiveStateFromLink(Component): ComponentType {
    return (props) => {
        const card = useRef(null)
        const [boolean, setBoolean] = useState(false)
        useEffect(() => {
            var cardHref = card.current?.getAttribute("href")

            if (!cardHref) return

            setBoolean(
                String(window.location.href).includes(
                    getSecondPart(String(cardHref))
                )
            )
        }, [])

        props.className += " collection-card"
        return (
            <Component
                {...props}
                ref={card}
                variant={boolean ? "Select" : "Default"}
            />
        )
    }
}

export function SearchShortcutKey(Component): ComponentType {
    function handleKeyDown(event) {
        var e = event
        console.log(e)
        console.log(e.keyCode)
        console.log(e.metaKey)
        console.log(e.key)
        if (
            (e.keyCode === 75 && e.altKey) ||
            // (e.keyCode === 75 && e.ctrlKey) ||
            (e.keyCode === 75 && e.metaKey)
        ) {
            console.log("search")

            var input = document.querySelector(".search-input button")
            console.log(input)
            input.click()
        }
    }

    return (props) => {
        useEffect(() => {
            document.addEventListener("keydown", handleKeyDown)
            var input = document.querySelector(".search-input")
            console.log(input)
            console.log("useeffect")
        }, [])

        props.className += " search-input"
        return <Component {...props} />
    }
}
